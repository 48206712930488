

import axios, { AxiosRequestConfig } from 'axios';
import { LoginPost, SignupPost } from './models';

const API = axios.create({ baseURL: '/.netlify/functions' });

API.interceptors.request.use((req: AxiosRequestConfig) => {
  const profileData = localStorage.getItem('profile');
  if (profileData) {
    const profile = JSON.parse(profileData);
    if (req.headers) {
      req.headers.Authorization = `Bearer ${profile.token}`;
    } else {
      req.headers = {
        Authorization: `Bearer ${profile.token}`,
      };
    }
  }
  return req;
});

  export const logIn = (formData: LoginPost) => API.post('/login', formData);
  export const signUp = (formData: SignupPost) => API.post('/signup', formData);

// Export the API instance for other parts of your code
export default API;
