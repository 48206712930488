import React from 'react';
//import { useParams } from 'react-router-dom';
import DraggableBoxComponent from './DraggableBoxComponent';
import Header from '../common/Header';


const Board: React.FC = () => {
    //const { id } = useParams();

    return (
        <div className="bg-background-600 text-surface-50 h-screen">
            <Header />
            <div className='w-full' style={{ height: `calc(100vh - 4rem)` }}>
            <DraggableBoxComponent />
            </div>
        </div>
    );
};

export default Board;
