import plantLogo from '../../assets/logo/plant-white-logo.svg'
import textLogo from '../../assets/logo/horiz-white-text-logo.svg'
import React from 'react';
import { Avatar } from '../common/components/Avatar';
import { isLoggedIn } from '../../utils/Session/selectors';
import { Button } from '../common/components/Button';
import { Link } from 'react-router-dom';
import { Menu } from '../common/components/Menu';

const Header = () => {
    return (
            <div className='h-16 bg-primary-700 flex flex-row items-center text-white'>
                <div className='w-1/4 pl-10'>
                    <img src={plantLogo} alt="Leaf Text Logo" className='h-12' />
                </div>
                <div className='w-1/2 flex justify-center'>
                    <img src={textLogo} alt="Leaf Text Logo" className='h-12' />
                </div>
                <div className='w-1/4 flex justify-end pr-10'>
                    {isLoggedIn() ? (
                        <Menu>
                            <Avatar name="gi a" />
                        </Menu>
                    ) : (
                        <Link to="/login">
                            <Button classname='cursor-pointer text-white bg-primary-400' text="Login"/>
                        </Link>
                    )}
                </div>
            </div>
    )
}

export default Header