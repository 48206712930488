import React, { FC, ReactNode, MouseEvent } from 'react';

interface ButtonProps {
  classname?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  solid?: boolean;
  text?: string;
  children?: ReactNode;
}

export const Button: FC<ButtonProps> = ({ classname, type = 'button', onClick, solid = false, text, children }) => {
  return (
    <button
      className={`${classname} rounded-full px-4 py-2 ${solid ? ' bg-primary-600 text-white' : 'bg-primary-100  text-black'}`}
      type={type}
      onClick={onClick}
    >
      {text ? text : children}
    </button>
  );
};
