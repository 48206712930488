import { Background } from 'network-background';
import React from 'react';
import { Button } from '../common/components/Button';
import { Link } from 'react-router-dom';
import Header from '../common/Header';

const Home = () => {
    return (
        <div className="flex ">
    
            {/* Background */}
            <div className='bg-surface-800 overflow-hidden h-screen relative'>
                <Background speedModifer={1000} amountOfDots={200} lineDistance={100} />
            </div>
        
            <div className="w-full absolute h-screen">
                {/* Header */}
                <Header />

                {/* Background Opacity */}
                <div className='bg-background-800 opacity-70' style={{ height: `calc(100vh - 6rem)` }}/>

                {/* Content */}
                <div className='text-white absolute top-[4rem] w-full flex justify-center items-center flex-col' style={{ height: `calc(100vh - 6rem)` }}>
                    <span className="text-6xl font-bold ">
                        Rediscover Your Family's&nbsp;
                        <span className='text-primary-500'>
                            Legacy
                        </span>
                    </span>
                    <span className="text-2xl font-semibold mt-4">
                        Capture and Preserve Your Ancestral Heritage
                    </span>
                    <Link to="/login">
                        <Button classname='cursor-pointer text-white bg-primary-500 py-3 px-6 text-xl mt-8' text="Get Started"/>
                    </Link>
                </div>

                {/* Footer */}
                <div className='h-8 bg-background-800 opacity-70 flex justify-center items-center text-background-50'>
                    Copyright {new Date().getFullYear()} Satherley Software Co
                </div> 
            </div>
        </div>
    )
}

export default Home