import React from 'react';
import pic from '../../assets/DJI.jpg'

interface CardProps {
    user: {
        first: string;
        last: string;
        description: string;
        picture: string;
        id: number;
    };
}

export const Card: React.FC<CardProps> = ({ user }) => {
    return (
        <div className="w-60 h-48 text-black rounded-2xl cursor-move bg-white">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 rounded-full w-4 h-4 z-10 border-2 border-white bg-surface-700 hover:bg-white cursor-grab"></div> {/* Top */}
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-blue-500 rounded-full w-4 h-4 z-10 border-2 border-white bg-surface-700 hover:bg-white cursor-grab"></div> {/* Bottom */}
            <div className="absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 rounded-full w-4 h-4 z-10 border-2 border-white bg-surface-700 hover:bg-white cursor-grab"></div> {/* Left */}
            <div className="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 bg-blue-500 rounded-full w-4 h-4 z-10 border-2 border-white bg-surface-700 hover:bg-white cursor-grab"></div> {/* Right */}
            
            <div className="h-8 w-12/12 flex items-center mx-5 py-4 text-md justify-center border-b">{user.first}&nbsp;{user.last}</div>
            <div className='flex flex-row '>
            <img
                src={pic}
                alt="User"
                className="w-1/2 h-[9rem] object-cover rounded-xl my-2 ml-2 mr-1"
            />
            <div className='text-xs my-2 ml-1 mr-2'>
                {user.description}
            </div>
            </div>
        </div>
    );
};
