import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import DashboardList from './DashboardList';
import LastEdited from './LastEdited';

const Dashboard: React.FC = () => {
    return (
        <>
            <Header />
            <div className='bg-background-600 text-surface-50  px-28 py-10' style={{ height: `calc(100vh - 6rem)` }}>
                <div className='flex flex-row justify-between'>
                    <div className='text-5xl pb-8 pl-8'>
                        Your Dashboard
                    </div>
                    <div className='bg-primary-600 hover:bg-primary-500 rounded px-4 py-2 h-10 text-xl flex items-center mr-8'>
                        Create New
                    </div>
                </div>
                <div className='flex justify-center mb-8'>
                    <LastEdited />
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='text-3xl  pb-8 pl-8'>
                        Your Trees
                    </div>
                </div>
                <DashboardList />
            </div>
            <Footer />
        </>  
    );
};

export default Dashboard;
