import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Background } from 'network-background'
import { Input } from '../common/components/Input';
import { Button } from '../common/components/Button';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { login } from '../../utils/Users';
import { LoginPost } from 'utils/Users/models';
import { Link } from 'react-router-dom';
import {FiCornerUpLeft} from 'react-icons/fi'

interface LoginProps {
}

const initialFormState: LoginPost = {
  email: '',
  password: '',
};

const Login: React.FC<LoginProps> = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [form, setForm] = useState(initialFormState);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(login(form, history));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  return (
    <div className="flex">

      <div className='bg-surface-800 overflow-hidden h-screen relative'>
        <Background speedModifer={1000} amountOfDots={200} lineDistance={100} />
      </div>

      <div className="w-full absolute h-screen flex justify-center items-center flex-col">
        <div className='z-[100] w-96 h-96 bg-surface-800 rounded-3xl opacity-95 shadow-3xl '>
          <Link to="/home">
            <FiCornerUpLeft className='text-white mt-5 ml-5' size={20}/>
          </Link>
          <form onSubmit={handleSubmit} className="pt-8 flex justify-center items-center w-full flex-col">
            <Input
              name="email"
              onChange={handleChange}
              placeholder="example@gmail.com"
              addedClass="px-0.5 my-2"
            />
            <Input
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              changeVisibility={handleShowPassword}
              showPassword={showPassword}
              placeholder="1password."
              addedClass="px-0.5 my-2"
            />
            <div className='flex flex-col space-y-3 m-4 pt-8'>
              <Button solid type="submit" text="Login"/>
              <Link to="/signup" className='text-surface-400 flex items-center justify-center'>Or sign up</Link>
            </div>
          </form>
        </div> 
        {/* <div className='pt-8 flex justify-center items-center text-background-300'>
          Copyright {new Date().getFullYear()} Satherley Software Co
        </div>  */}
      </div>
    </div>
  );
};

export default Login;
