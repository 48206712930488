const getProfileData = () => {
    const profileData = localStorage.getItem('profile');
    try {
        if (profileData) {
            return JSON.parse(profileData);
        }
    } catch (error) {
        console.error('Error parsing profile data:', error);
    }
    return null;
};
  
export const isLoggedIn = () => {
    const profile = getProfileData();
    return !!profile;
};
  
export const isNotLoggedIn = () => {
    return !isLoggedIn();
};
  
export const getUsername = () => {
    const profile = getProfileData();
    return profile?.result?.name?.replaceAll(',', '') || '';
};
  
export const getEmail = () => {
    const profile = getProfileData();
    return profile?.result?.email || '';
};
  