// import { AUTH } from '../actionTypes';
// import * as api from './api.js';

// export const login = (formData, router) => async (dispatch) => {
//     try {
//       const { data } = await api.logIn(formData);

//       dispatch({ type: AUTH, data });

//       router('/home');
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   export const signup = (formData, router) => async (dispatch) => {
//     try {
//       const { data } = await api.signUp(formData);

//       dispatch({ type: AUTH, data });

//       router('/home');
//     } catch (error) {
//       console.log(error);
//     }
//   };

export const getDashboard = () => {
    return ({
        lastEdited: {
            dateCreated: 1401135538000,
            name: 'New Family Tree',
            id: '123456789',
            lastEdited: 1651135538000,
        },
        num: 23
    })
}
    