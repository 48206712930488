import React, { ChangeEvent, MouseEvent, FocusEvent } from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

interface InputProps {
  name: string;
  type?: string;
  showPassword?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  changeVisibility?: () => void;
  placeholder?: string;
  addedClass?: string;
}

export const Input: React.FC<InputProps> = ({
  name,
  type = 'text',
  showPassword = false,
  onChange = () => {},
  onClick = () => {},
  onFocus = () => {},
  onBlur = () => {},
  changeVisibility = () => {},
  placeholder,
  addedClass,
}) => {
  return (
    <>
      <input
        className={`${addedClass} border-b px-2 pb-1 border-primary-500 !bg-surface-800 text-primary-500 placeholder-primary-700 focus:outline-none`}
        name={name}
        type={showPassword ? 'text' : type}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        spellCheck={false}
      />
      {type === 'password' && (
        <div className=" -mt-8 mb-3 -mr-[11rem]" onClick={changeVisibility}>
          {showPassword ? (
            <AiFillEye size="18" className="text-primary-700" />
          ) : (
            <AiFillEyeInvisible size="18" className="text-primary-700" />
          )}
        </div>
      )}
    </>
  );
};