import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='h-8 bg-background-600 flex justify-center items-center text-background-50'>
            <Link to="/">
                <span>
                    Copyright {new Date().getFullYear()} Satherley Software Co
                </span>
            </Link>
        </div>        
    )
}

export default Footer