import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

interface MenuProps {
    children?: ReactNode;
}

export const Menu: React.FC<MenuProps> = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div onMouseEnter={() => setMenuOpen(true)} onMouseLeave={() => setMenuOpen(false)} className='relative'>
            {children}
            {menuOpen && (
                <div
                    className="absolute rounded bg-primary-400 z-[999999] opacity-100"
                    style={{
                        left: '50%', // Center horizontally
                        transform: 'translate(-50%, 0)' // Adjusts for the menu's own width and height
                    }}
                >
                <div className='w-full h-16 flex items-center p-2'>
                    This is your name
                </div>
                    <Link to="/profile">
                        <div className='w-full h-8 rounded hover:bg-primary-500 flex items-center p-2'>
                            Profile
                        </div>
                    </Link>
                    <Link to="/dashboard">
                        <div className='w-full h-8 rounded hover:bg-primary-500 flex items-center p-2'>
                            Dashboard
                        </div>
                    </Link>
                    <Link to="/peepeepoopoo">
                        <div className='w-full h-8 rounded hover:bg-primary-500 flex items-center p-2'>
                            Third
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
};
