import React, { useState, useEffect, useRef } from 'react';
import { Card } from './Card';

const containerStyle: React.CSSProperties = {
    width: '1500px',
    height: '900px',
    position: 'relative',
    border: '1px solid black',
};

interface DraggableBoxProps {
    initialX: number;
    initialY: number;
    containerWidth: number;
    containerHeight: number;
    boxWidth: number;
    boxHeight: number;
}

const DraggableBox: React.FC<DraggableBoxProps> = ({
    initialX,
    initialY
}) => {
    const [pos, setPos] = useState({ x: initialX, y: initialY });
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault();
        setOffset({ x: e.clientX - pos.x, y: e.clientY - pos.y });
        setIsDragging(true);
    };

    const handleMouseMove = (e: MouseEvent) => {
        e.stopPropagation()
        if (!isDragging) return;
        let x = e.clientX - offset.x;
        let y = e.clientY - offset.y;

        // Snap to grid
        x = Math.round(x / 5) * 5;
        y = Math.round(y / 5) * 5;

        setPos({ x, y });
    };

    const handleMouseUp = (e: MouseEvent) => {
        e.stopPropagation()
        setIsDragging(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, offset]);

    return (
        <div
            style={{ position: 'absolute', left: `${pos.x}px`, top: `${pos.y}px` }}
            onMouseDown={handleMouseDown}
        >
            <Card user={{ first: 'Booby', last: 'Dlyan', description: 'Loriem ispum wosnn ieamde womey alsem ajlebiueft', id: Math.random(), picture: "awdwad"}} />
        </div>
    );
};

const DraggableContainer: React.FC = () => {
    const [scale, setScale] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = useState({ width: 1500, height: 900 }); // Default size

    const [isPanning, setIsPanning] = useState(false);
    const [panStart, setPanStart] = useState({ x: 0, y: 0 });
    const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setContainerSize({ width, height });
        }
    }, [scale]);

    const scalingStyle: React.CSSProperties = {
        ...containerStyle,
        transform: `scale(${scale})`,
    };

    const containerTransformStyle: React.CSSProperties = {
        transform: `translate(${panOffset.x}px, ${panOffset.y}px)`,
        width: `${containerSize.width}px`,
        height: `${containerSize.height}px`,
        position: 'absolute',
    };

    const handleWheel = (e: React.WheelEvent) => {
        e.preventDefault();
        const newScale = scale + e.deltaY * -0.002;
        setScale(Math.min(Math.max(newScale, 0.5), 2));
    };
    
    const handleMouseDown = (e: React.MouseEvent) => {
        console.log("down")
        e.preventDefault();
        setIsPanning(true);
        setPanStart({ x: e.clientX - panOffset.x, y: e.clientY - panOffset.y });
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!isPanning) return;
        let dx = e.clientX - panStart.x;
        let dy = e.clientY - panStart.y;
    
        // Calculate scaled limits
        const scaledLimit = 1000 / scale; // Adjust the limit based on the scale
    
        // Implement boundary checks for panning
        dx = Math.max(Math.min(dx, scaledLimit), -scaledLimit);
        dy = Math.max(Math.min(dy, scaledLimit), -scaledLimit);
    
        setPanOffset({ x: dx, y: dy });
    };

    const handleMouseUp = () => {
        setIsPanning(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isPanning, panStart]);

    const containerWidth = containerSize.width;
    const containerHeight = containerSize.height;
    const boxWidth = 50;
    const boxHeight = 50;

    return (
        <div className='w-full h-full' style={{
            backgroundImage: `linear-gradient(to right, #333 1px, transparent 1px), linear-gradient(to bottom, #333 1px, transparent 1px)`,
            backgroundSize: '20px 20px'
        }}>
         <div ref={containerRef} onWheel={handleWheel} style={scalingStyle}>
                <div onMouseDown={handleMouseDown} style={containerTransformStyle}>
            <DraggableBox
                initialX={10}
                initialY={10}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                boxWidth={boxWidth}
                boxHeight={boxHeight}
            />
            <DraggableBox
                initialX={70}
                initialY={70}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                boxWidth={boxWidth}
                boxHeight={boxHeight}
            />
            <DraggableBox
                initialX={130}
                initialY={130}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                boxWidth={boxWidth}
                boxHeight={boxHeight}
            />
            </div>
        </div>
        </div>
    );
};

export default DraggableContainer;
