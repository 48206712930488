import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedIn } from '../../utils/Session/selectors';

interface AuthedRouteProps {
  children: React.ReactNode;
}

const AuthedRoute: React.FC<AuthedRouteProps> = ({ children }) => {
  return isLoggedIn() ? <>{children}</> : <Navigate to='/login' />;
};

export default AuthedRoute;
