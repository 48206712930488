import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Routes from './components/Routes/Routes';

import { reducers } from './utils';

import './index.css'

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <div className='font-poppins'>
            <Routes />
        </div>
    </Provider>
)

const container: any = document.getElementById('root');
const root = createRoot(container)
root.render( app );