import React from 'react';
import { Link } from 'react-router-dom';
import {BsRobot} from 'react-icons/bs'

const NotFound: React.FC = () => (
    <div className='bg-background-600 text-surface-50 h-screen'>
        <div className='flex flex-col h-screen justify-center items-center text-center '>
            <BsRobot size={200}/>
            <p className='pt-6 text-6xl font-bold'>
                That's a 404!
            </p>
            <br/><br/>
            <p className='text-4xl mb-16'>
                Repair crews are on their way. In the meantime, view your&nbsp;
                <Link to="/dashboard" className='text-primary-500 cursor-pointer'>
                    Dashboard
                </Link>
            </p>
        </div>
    </div>
);

export default NotFound;
