// import { AUTH } from '../actionTypes';
// import * as api from './api.js';

// export const login = (formData, router) => async (dispatch) => {
//     try {
//       const { data } = await api.logIn(formData);

//       dispatch({ type: AUTH, data });

//       router('/home');
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   export const signup = (formData, router) => async (dispatch) => {
//     try {
//       const { data } = await api.signUp(formData);

//       dispatch({ type: AUTH, data });

//       router('/home');
//     } catch (error) {
//       console.log(error);
//     }
//   };

export const getTrees = () => [
    {
        dateCreated: 1401135538000,
        name: 'New Family Tree',
        id: '123456789',
        lastEdited: 1651135538000,
    },
    {
        dateCreated: 1299135538000,
        name: 'New Family Tree',
        id: '123456789',
        lastEdited: 1501135538000,
    },
    {
        dateCreated: 1601135538000,
        name: 'New Family Tree',
        id: '123456789',
        lastEdited: 1700035538000,
    },
];

export const getTree = () => {
    return {
        lastEdited: {
            when: new Date(),
            who: '19328732',
            name: {
                first: 'James',
                last: 'Satherley',
            },
        },
        created: {
            when: new Date(),
            who: '19328732',
            name: {
                first: 'James',
                last: 'Satherley',
            },
        },
        nodes: [
            {
                description: 'This is a person',
                image: {
                    name: 'image',
                    contentType: 'png',
                    data: [],
                },
                name: {
                    first: 'Roger',
                    last: 'Dick',
                },
                id: '9247267',
                x: 10,
                y: 20,
                connected: [],
            },
            {
                description: 'This is a maid',
                image: {
                    name: 'image',
                    contentType: 'png',
                    data: [],
                },
                name: {
                    first: 'Dingleberry',
                    last: 'McCriff',
                },
                id: '9247267',
                x: 300,
                y: 100,
                connected: [],
            },
            {
                description: 'This is a son',
                image: {
                    name: 'image',
                    contentType: 'png',
                    data: [],
                },
                name: {
                    first: 'Philip',
                    last: 'Dick',
                },
                id: '9247267',
                x: 100,
                y: 200,
                connected: [],
            },
            {
                description: 'This is a wife',
                image: {
                    name: 'image',
                    contentType: 'png',
                    data: [],
                },
                name: {
                    first: 'Penny',
                    last: 'Smith',
                },
                id: '9247267',
                x: 200,
                y: 300,
                connected: [],
            },
        ],
        description: 'Description of the board',
        id: '28438674',
        title: 'Title of the board',
    };
};
