import React from 'react';
import { getTrees } from '../../utils/Trees/actions';
import { Link } from 'react-router-dom';

const DashboardList: React.FC = () => {
    const trees = getTrees();

    if (trees.length === 0) {
        return <div>No trees available, create?</div>;
    }

    return (
        <div>
            {trees.map((tree) => (
                <div className='border-t border-surface-600 px-16 py-8 flex flex-row justify-between items-center' key={tree.id}>
                    <div className=''>
                        <div className='text-2xl pb-1'>
                            {tree.name}
                        </div>
                    </div>
                    <div className=''>
                        <div className='text-lg pb-1'>
                            Date created
                        </div>
                        <div className='text-md text-surface-200'>
                            {new Date(tree.dateCreated).toDateString()}
                        </div>
                    </div>
                    <div className=''>
                        <div className='text-lg pb-1'>
                            Last Edited
                        </div>
                        <div className='text-md text-surface-200'>
                            {new Date(tree.lastEdited).toLocaleTimeString()}&nbsp;{new Date(tree.dateCreated).toLocaleDateString()}
                        </div>
                    </div>
                    <div className=''>
                        <Link to={`/board/${tree.id}`}>
                            <div className='px-4 py-2 bg-primary-600 hover:bg-primary-500 rounded text-xl'>
                                Edit&nbsp;&nbsp;{'>'}
                            </div>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DashboardList;
