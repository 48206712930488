import { AUTH } from '../actionTypes';
import * as api from './api';
import { LoginPost, SignupPost } from './models';

export const login = (formData: LoginPost, router: any): any => async (dispatch: any) => {
  try {
    const { data } = await api.logIn(formData);

    dispatch({ type: AUTH, data });

    router('/home');
  } catch (error) {
    console.error(error);
  }
};

export const signup = (formData: SignupPost, router: any): any => async (dispatch: any) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });

    router('/home');
  } catch (error) {
    console.error(error);
  }
};
