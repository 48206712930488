import React from 'react';
import PropTypes from 'prop-types';

const Profile = () => (
    <div className=''>
        adw
    </div>
)

Profile.propTypes = {
  isAuthed: PropTypes.bool,
};

Profile.defaultProps = {
  isAuthed: false,
};

export default Profile