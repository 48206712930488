import React from 'react';
import { getDashboard } from '../../utils/Dashboard/actions'; // Assuming you have a type for Tree
import { Link } from 'react-router-dom';
import { Button } from '../common/components/Button';

const LastEdited: React.FC = () => {
    const myDashboard = getDashboard();

    return (
        <div className='flex flex-row items-center justify-between my-12 w-1/2 border-t border-b border-surface-600 p-10'>
            <div className='text-2xl '>You last edited:</div>
            <div className='flex flex-col'>
                <div className='text-2xl'>{myDashboard.lastEdited.name}</div>
                <div className='text-md text-surface-200'>at {new Date(myDashboard.lastEdited.lastEdited).toLocaleTimeString()}</div>
            </div>
                
            <Link to={`/board/${myDashboard.lastEdited.id}`}>
                <Button classname='cursor-pointer !text-white bg-primary-500 py-3 px-6 text-xl' text="Continue Editing"/>
            </Link>
        </div>
    );
};

export default LastEdited;
