import React, { ReactNode } from 'react';

interface AvatarProps {
    name?: string;
    children?: ReactNode;
}

export const Avatar: React.FC<AvatarProps> = ({ name, children }) => {
    return (
        <div
            className="rounded-full h-10 w-10 flex justify-center bg-primary-400 items-center text-surface-50 hover:bg-primary-500"
        >
            {name ? name : children}
        </div>
    );
};
