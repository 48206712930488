import React from 'react';
import { BrowserRouter as Router, Routes as RoutesList, Route } from 'react-router-dom';

import Home from '../Home/Home';
import Login from '../Auth/Login';
import Board from '../Board/Board';
import Dashboard from '../Dashboard/Dashboard';
import NotFound from '../404/NotFound';
import Profile from '../Profile/Profile';
import AuthedRoute from './AuthedRoute';
import SignUp from '../Auth/SignUp';

const Routes = () => {
    return (
        <Router>
            <RoutesList>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/dashboard" element={<AuthedRoute><Dashboard /></AuthedRoute>} />
                <Route path="/board/:id" element={<AuthedRoute><Board /></AuthedRoute>} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<AuthedRoute><Profile /></AuthedRoute>} />
                <Route path="*" element={<NotFound />} />
            </RoutesList>
        </Router>
    );
};

export default Routes;
